/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from "keycloak-js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
// eslint-disable-next-line no-unused-vars
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const keycloakConfig = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  };

  const keycloak = new Keycloak(keycloakConfig);

  const initOptions = {
    onLoad: 'login-required',
    checkLoginIframe: true,
    pkceMethod: 'S256'
  }

  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
      <BrowserRouter>
        <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="/*" name="Home" element={<DefaultLayout />} />
            </Routes>
        </Suspense>
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}

export default App;
