/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext();

function AuthContextProvider(props) {
  const [Token, setToken] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [adminLogged, setadminLogged] = useState(false);

  const checkToken = async () => {
    if (Cookies.get("admin") === true) {
      setadminLogged(true);
      setToken(Cookies.get("whatsapp_token"));
      setUserRole(Cookies.get("whatsapp_user_type"));
    } else {
      setadminLogged(false);
      setToken(Cookies.get("whatsapp_token"));
      setUserRole(Cookies.get("whatsapp_user_type"));
    }
    // console.log("data", Cookies.get("adminLogged"));
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <AuthContext.Provider value={{ Token, userRole, adminLogged }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
